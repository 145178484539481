import { ChargeSession } from "api/domains/chargingStations/types";
import { buildDataQuery } from "api/protocols/OData/dataQueryBuilder";
import paths from "appRoutes/paths";
import strings from "assets/strings";
import { ReportsControls } from "components";
import { TableRemoteSorting } from "components/Table";
import { ISortOptions } from "components/Table/types";
import { Container } from "drax-design-system";
import {
  startEndDateParcer,
  useStartEndDateFilterNavigation,
} from "hooks/useStartEndDateFilterNavigation";
import { chargeSessionsTableConfig } from "pages/Reports/reportsTables";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  queryChargeSessionsData,
  selectReportsState,
} from "store/slices/reports";
import { ChargeSessionsFilter } from "./types";
import useChargeSessionsToolbar from "./useChargeSessionsToolbar";

const parcer = {
  ...startEndDateParcer,
  locationId: (x: string) => x,
  initiatedBy: (x: string) => x,
};

const ChargeSessions: FunctionComponent = () => {
  const [filter, _, setFilter] =
    useStartEndDateFilterNavigation<ChargeSessionsFilter>(parcer);

  const setDates = useCallback(
    ({ startDate, endDate }) => {
      setFilter({
        startDate,
        endDate: endDate ?? startDate,
        locationId: null,
        initiatedBy: null,
      });
    },
    [setFilter]
  );

  const [orderBy, setOrderBy] = useState<ISortOptions<ChargeSession>>({
    sortKey: "startTime",
    sortDir: "desc",
  });

  const dispatch = useDispatch();
  const { chargeSessions, chargeSessionsError, chargeSessionsCount } =
    useSelector(selectReportsState);

  const [isLoading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(
    async (startIndex: number, endIndex: number) => {
      const { startDate, endDate, locationId, initiatedBy } = filter;
      const nextEndDate = new Date(endDate);
      nextEndDate.setDate(nextEndDate.getDate() + 1);
      const query = buildDataQuery<ChargeSession>((b) => {
        b.skip(startIndex)
          .top(endIndex - startIndex)
          .filter("startTime", "ge", startDate)
          .filter("endTime", "lt", nextEndDate)
          .orderBy(orderBy.sortKey, orderBy.sortDir)
          .count();

        if (locationId != null) {
          b.filter("locationId", "eq", locationId);
        }

        if (initiatedBy != null) {
          b.filter("initiatedBy", "eq", initiatedBy);
        }
      });

      await dispatch(queryChargeSessionsData(query));
    },
    [dispatch, orderBy, filter]
  );

  useEffect(() => {
    setLoading(true);
    loadData(0, 100).finally(() => {
      setLoading(false);
    });
  }, [dispatch, loadData, setLoading]);

  const ChargeSessionsToolbar = useChargeSessionsToolbar(filter, setFilter);

  return (
    <Container layout="maxWidth">
      <ReportsControls
        linkUrl={paths.reports}
        startDate={filter.startDate}
        endDate={filter.endDate}
        ///@ts-ignore
        setDates={setDates}
      ></ReportsControls>
      <TableRemoteSorting
        ToolbarComponent={ChargeSessionsToolbar}
        dataKey="id"
        columns={chargeSessionsTableConfig}
        data={chargeSessions ?? ([] as any)}
        title={strings.reports.allReports.chargeSessions}
        dataError={chargeSessionsError}
        isLoading={isLoading}
        showTooltip={false}
        tableHeight={500}
        showExportDataCount={true}
        loadMoreData={loadData}
        totalCount={chargeSessionsCount}
        sortOptions={orderBy}
        setSortOptions={setOrderBy as any}
      />
    </Container>
  );
};

export default ChargeSessions;
