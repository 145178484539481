import { Libraries, LoadScript } from "@react-google-maps/api";
import api from "api";
import {
  Authorization,
  isEsEvUser,
  isEvAdmin,
  Role,
} from "components/Authorization";
import Header from "components/Header";
import PageError from "components/PageError";
import config from "config/app";
import {
  IUserInfo,
  useAuthenticationContext,
} from "contexts/AuthenticationContext";
import { Subscription, useSubscription } from "contexts/subscriptionContext";
import { useExpiration } from "hooks/useExpiration";
import { useOrgIdInterceptor } from "hooks/useOrgIdInterceptor";
import AccessDenied from "pages/AccessDenied/AccessDenied";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { setUser } from "store/slices/auth";
import { IUserInfoState } from "store/slices/auth/types";
import { findOrganisationSubscriptions } from "utils/subscriptionUtils";
import Loader from "../Loader";
import "./styles.scss";
export interface IAppLayout {
  children?: React.ReactNode;
}

const libraries: Libraries = ["geometry"];

const isValidUser = (user: IUserInfo | null): user is IUserInfo =>
  user != null && (user?.name?.length > 0 || user?.email?.length > 0);

const AppLayout: React.FC<IAppLayout> = ({ children }) => {
  const { user, isLoading, isLoggedIn, error, login } =
    useAuthenticationContext();
  const [_, setTimeoutSeconds] = useState<number>();

  const subscription = useSubscription();
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname: returnUrl, search } = location;

  useEffect(() => {
    const getUserOrganisations = async (user: IUserInfo) => {
      let subscriptions: Subscription[] | null = null;
      let orgId = user.orgId;
      try {
        const isUser = isEsEvUser(user);
        if (isUser) {
          const data = await api.domains.Organisations.getUserOrganisations();
          if (isEvAdmin(user)) {
            const searchParams = new URLSearchParams(search);
            const paramsOrgId = searchParams.get(config.app.orgIdParamName);
            if (paramsOrgId != null && paramsOrgId?.length > 0) {
              subscriptions = findOrganisationSubscriptions(data, paramsOrgId);
              orgId = paramsOrgId;
            }
          }
          if (subscriptions == null) {
            subscriptions = findOrganisationSubscriptions(data, user.orgId);
            orgId = user.orgId;
          }
        }
      } catch (error) {}
      subscription.setSubscriptions(subscriptions ?? [], orgId);
      setTimeoutSeconds(user.sessionExpiresIn);
      const userWithSubscriptions = {
        ...user,
        subscriptions,
      } as IUserInfoState;
      dispatch(setUser({ user: userWithSubscriptions }));
    };

    if (isValidUser(user)) {
      getUserOrganisations(user);
    }
  }, [user]);

  useOrgIdInterceptor(subscription.orgId ?? user?.orgId);

  const loginCb = React.useCallback(() => {
    login(returnUrl);
  }, [login, returnUrl]);

  useEffect(() => {
    if (!isLoading && !isLoggedIn && error == null) {
      loginCb();
    }
  }, [isLoggedIn, isLoading, loginCb, error]);

  useExpiration(user?.sessionTimeout ?? 0);

  if (error) {
    return (
      <PageError
        title={error?.title}
        message={error?.message}
        withPanel={false}
      />
    );
  }

  if (isLoading || !isLoggedIn) {
    return <Loader />;
  }

  return (
    <main className="main-content-wrapper">
      <Header />
      <section className="main-content" id="main-content">
        <Authorization role={Role.User} fallback={<AccessDenied />}>
          <LoadScript googleMapsApiKey={config.mapsKey} libraries={libraries}>
            <Outlet key={subscription.orgId} />
          </LoadScript>
        </Authorization>
      </section>
      <div className="main-content__footer">
        {`v${config.version || "x.x.x"}`}
      </div>
    </main>
  );
};

export default AppLayout;
