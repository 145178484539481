import { RedirectHome } from "components";
import AppLayout from "components/AppLayout";
import {
  Subscription,
  SubscriptionTemplate,
} from "contexts/subscriptionContext";
import {
  Dashboard,
  EnergyConsumption,
  NotificationsPage,
  PageNotFound,
  Support,
} from "pages";
import Correlation from "pages/Correlation";
import { Reports } from "pages/Reports";
import { Navigate, Route, Routes } from "react-router-dom";
import paths from "./paths";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path={paths.reports + "/*"} element={<Reports />} />
        <Route path={paths.support} element={<Support />} />
        <Route path={paths.home} element={<Dashboard />} />
        <Route path={paths.notifications} element={<NotificationsPage />} />
        <Route path={paths.notFound} element={<PageNotFound />} />
        <Route
          path={paths.consumption}
          element={
            <SubscriptionTemplate
              sub={Subscription.Energy}
              ErrorComponent={RedirectHome}
            >
              <EnergyConsumption />
            </SubscriptionTemplate>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to={"/404"} replace={true} />} />
      <Route path={paths.correlation} element={<Correlation />} />
      <Route path={paths.home} element={<Navigate to={paths.home} replace />} />
    </Routes>
  );
};
//
export default AppRoutes;
