import { DomainTypes } from "./types";
const runtimeConfig: any = (window as any).runtimeEnv ?? {};

const config = {
  requireLogin: true,
  mapsKey: runtimeConfig[DomainTypes.maps],
  googleTagContainerId: runtimeConfig[DomainTypes.tagManager],
  version: process.env.REACT_APP_VERSION_INFO,
  api: {
    domain: {
      chargeStations: "/api/chargingstations",
      chargeStationsFinance: "/api/chargingstations-finance",
      vehicles: "/api/vehicles",
      messaging: "/api/notifications",
      enquiry: "/api/enquiry",
      users: "/api/user",
      organisations: "/api/organisations",
      consumption: "/api/consumption/v1.1",
      hubs: "/hubs",
    },
    env: "",
    version: "1.0",
  },
  app: {
    vehicleLowRangeLevel: 50,
    lowRangePercent: 0.75, // Return 75% of the range to the vehicle
    mile: 1609.344, // One mile in meters
    dashboard: {
      map: {
        zoom: {
          defaultZoom: 6,
        },
        defaultCoords: {
          // UK
          lat: 52.95425628917947,
          lng: -1.974859503478772,
        },
      },
    },
    exportFileName: "drax_ev_data",
    vehicleToStationProximity: 5, // distance tolerance 5 meters
    dateTimeTolerance: 60000, // 60 seconds
    vehicleLowChargeLevel: 30, // Percent
    defaultTimeoutSeconds: 30 * 60, // 30 minutes
    orgIdParamName: "organisationId",
  },
};
export default config;
