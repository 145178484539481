import axios, { AxiosError, AxiosResponse } from "axios";
import { useAuthenticationContext } from "contexts/AuthenticationContext";
import { useCallback, useEffect, useState } from "react";
import { EventsType, useIdleTimer } from "react-idle-timer";
import { fetchBffUser } from "./useBffUser";
import { useDocumentEventListener } from "./useDocumentEventListener";

const DELAY_SEC = -5;

const events: EventsType[] = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange",
];

const authEndpoints = ["/bff/", "/signin-oidc", "/signout-callback-oidc"];

export const useExpiration = (sessionTimeout: number) => {
  const [expiresAt, setExpiresAt] = useState<number>();
  const { isLoggedIn, login, logout } = useAuthenticationContext();
  const throttle = Math.floor(sessionTimeout * 0.1 * 1000);

  const checkSessionExpirationCb = useCallback(
    (slide: boolean) => {
      fetchBffUser(slide)
        .then((userClaims) => {
          const seconds = userClaims?.["bff:session_expires_in"] ?? 0;
          if (seconds > Math.abs(DELAY_SEC)) {
            const nextExpiresInTime = new Date(Date.now() + seconds * 1000);
            setExpiresAt(nextExpiresInTime.getTime());
          } else {
            logout();
          }
        })
        .catch((err: any) => {
          if (err?.response?.status === 401) {
            login();
          }
        });
    },
    [logout, login]
  );

  const onAction = useCallback(
    (event?: Event) => {
      if (expiresAt == null) {
        return;
      }
      const now = Date.now();
      const remainingBeforeExpire = (expiresAt - now) / 1000;
      const halfwayExpirationWindow = sessionTimeout / 2;

      if (halfwayExpirationWindow > remainingBeforeExpire) {
        checkSessionExpirationCb(true);
      }
    },
    [expiresAt, sessionTimeout, checkSessionExpirationCb]
  );

  useEffect(() => {
    const id = axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          const url: string = error.request.responseURL ?? "";
          const isAuthFailure = authEndpoints.some((x) => url.indexOf(x) >= 0);
          if (!isAuthFailure) {
            checkSessionExpirationCb(false);
          }
        }

        return Promise.reject(error as Error);
      }
    );
    return () => {
      axios.interceptors.response.eject(id);
    };
  }, [checkSessionExpirationCb]);

  const { getLastActiveTime } = useIdleTimer({
    disabled: sessionTimeout === 0,
    throttle,
    events,
    crossTab: true,
    syncTimers: 200,
    leaderElection: true,
    onAction,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    let checkTimeout = (expiresAt ?? 0) - Date.now() + DELAY_SEC * 1000;

    if (checkTimeout < 0) {
      checkTimeout = Math.abs(DELAY_SEC * 1000);
    }

    const id = setTimeout(() => {
      const lastActiveTime = getLastActiveTime()?.getTime();
      const now = Date.now();
      const slide =
        lastActiveTime != null ? now - lastActiveTime < throttle : false;

      checkSessionExpirationCb(slide);
    }, checkTimeout);

    return () => {
      clearTimeout(id);
    };
  }, [
    checkSessionExpirationCb,
    getLastActiveTime,
    isLoggedIn,
    throttle,
    expiresAt,
  ]);

  const visibilityChangeCb = useCallback(() => {
    if (document.hidden || !isLoggedIn) {
      return;
    }
    checkSessionExpirationCb(false);
  }, [checkSessionExpirationCb, isLoggedIn]);

  useDocumentEventListener("visibilitychange", visibilityChangeCb, 100);
};
