import strings from "assets/strings";
import { useAuthenticationContext } from "contexts/AuthenticationContext";
import { Button } from "drax-design-system";
import React from "react";
import gtmTags from "services/Analytics/GtmTags";
import { ReactComponent as DraxLogo } from "./drax-power-blue-logo.svg";
import "./styles.scss";

const Correlation: React.FC = () => {
  const { profile } = strings;
  const { logout, isLoggedIn, login } = useAuthenticationContext();

  return (
    <section className="correlation-page">
      <section className="drax-logo">
        <DraxLogo />
      </section>
      <section className="action-panel">
        <div className="title">Log out?</div>
        <div className="description">
          You're currently logged in. Choose 'Back' to remain logged in or 'Log
          out' to end your session.
        </div>
        <div className="buttons">
          <Button
            appearance="secondary"
            onClick={() => window.location.replace("/")}
            title={"Back"}
            className={gtmTags.logOut}
          />
          <Button
            appearance="primary"
            onClick={isLoggedIn ? logout : () => login()}
            title={profile.logOut}
            className={gtmTags.logOut}
          />
        </div>
      </section>
    </section>
  );
};

export default Correlation;
