import { Settings as SettingsIcon } from "assets/images/svgs";
import Select from "components/Select";
import { ISelectOption } from "components/Select/Select";
import { stylesFactory } from "components/Select/styles";
import config from "config/app";
import { useSubscription } from "contexts/subscriptionContext";
import { Grid } from "drax-design-system";
import { useUserOrganisations } from "hooks/useUserOrganisations";
import RcTooltip from "rc-tooltip";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { sortBy } from "utils/arrayUtils";
import { findOrganisationSubscriptions } from "utils/subscriptionUtils";
import "./styles.scss";

const organisationStyles = stylesFactory({
  menuWidth: "230px",
  singleValueWidth: "230px",
});

const DefaultOrganisation = {
  value: "-",
  label: "Default",
};

const Settings: FunctionComponent<{ userOrgId?: string }> = ({ userOrgId }) => {
  const subContext = useSubscription();
  const navigate = useNavigate();
  const { organisations } = useUserOrganisations();

  const options = useMemo(() => {
    const options = (organisations ?? [])
      .sort(sortBy("name", (x: string) => x.toLowerCase()))
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    options.unshift(DefaultOrganisation);
    return options;
  }, [organisations]);

  const setSubscriptionsCb = useCallback(
    (id: string) => {
      const subscriptions = findOrganisationSubscriptions(organisations, id);
      if (subscriptions) {
        subContext.setSubscriptions(subscriptions, id);
        return true;
      }
      subContext.setSubscriptions([], "");
      return false;
    },
    [organisations, subContext]
  );

  const selectOrganisationCb = useCallback(
    (id: string) => {
      const result = setSubscriptionsCb(id);
      if (result) {
        navigate({
          pathname: "/",
          search: `${config.app.orgIdParamName}=${id}`,
        });
      } else {
        navigate("/");
      }
    },
    [setSubscriptionsCb, navigate]
  );

  const value =
    options.find((o) => o.value === subContext.orgId) ?? DefaultOrganisation;

  return (
    <RcTooltip
      trigger={["click"]}
      placement="bottomLeft"
      overlayClassName="d-Panel profile-settings__tooltip-overlay"
      overlayInnerStyle={{ border: "none" }}
      overlay={
        <SettingsOverlay
          options={options}
          value={value}
          selectOrganisation={selectOrganisationCb}
        />
      }
    >
      <section className="profile-settings">
        <SettingsIcon />
      </section>
    </RcTooltip>
  );
};

const SettingsOverlay: FunctionComponent<{
  options: ISelectOption[];
  value: ISelectOption;
  selectOrganisation: (id: string) => void;
}> = ({ options, selectOrganisation, value }) => (
  <Grid template="1fr">
    <Select
      options={options}
      value={value}
      styles={organisationStyles}
      showIcon={false}
      label={"Organisation"}
      placeholder=""
      onSelectChange={(el) => {
        selectOrganisation(el.value as string);
      }}
      isClearable={false}
      data-testid="organisations-dropdown"
    />
  </Grid>
);

export default Settings;
