import Domain from "api/common/Domain";
import { IHttpClient } from "api/httpClient";
import { IODataClient, ODataClient } from "api/ODataClient";
import { IODataQuery } from "api/protocols/OData/types";
import { IDomainRequestDetails } from "api/types";
import strings from "assets/strings";
import urls from "../urls";
import {
  ChargeSession,
  ChargeSessionLocation,
  FinanceReportingResponse,
  IChargingStation,
  IChargingStationFullDetails,
  IChargingStationResponse,
  ICSSavingsResponse,
  IFinancialReportingSummaryResponse,
  IPerformancePayload,
  IUpTimeTrendsResponse,
  Premise,
  TariffUpdateRequest,
} from "./types";

const str = strings.api.errors.chargeStations;

export class ChargingStations extends Domain {
  httpClient: IHttpClient;
  odataClient: IODataClient;

  constructor(httpClient: IHttpClient) {
    super();
    this.httpClient = httpClient;
    this.odataClient = new ODataClient(httpClient);
  }

  async getChargingStations(): Promise<IChargingStation[]> {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: urls.chargeStations,
    };

    const response = (await this.httpClient.request(
      request
    )) as IChargingStationResponse;

    if (!Array.isArray(response?.items)) {
      throw new Error(str.fetchChargeStations);
    }

    return response.items;
  }

  async getChargeStation(
    locationId: string
  ): Promise<IChargingStationFullDetails> {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.chargeStations}/${locationId}`,
    };

    const response = (await this.httpClient.request(
      request
    )) as IChargingStationFullDetails;

    if (!(response != null && Object.keys(response).length)) {
      throw new Error(str.fetchChargeStation);
    }

    return response;
  }

  async getCSPerformanceMetrics(): Promise<IPerformancePayload> {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.chargeStations}/performancetrends`,
    };

    const response = (await this.httpClient.request(
      request
    )) as IPerformancePayload;

    if (this.isError(response) || this.isEmptyObj(response)) {
      throw new Error(str.fetchChargeStationMetrics);
    }

    return response;
  }

  async getChargingSessions() {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.chargeStations}/chargesessions`,
    };

    const response = (await this.httpClient.request(
      request
    )) as ChargeSession[];

    if (!Array.isArray(response)) {
      throw new Error(str.fetchChargeStationsSessions);
    }

    return response;
  }

  async queryChargingSessions(query: IODataQuery) {
    const route = "/query";
    const resource = "/ChargingsSessions";

    const data = await this.odataClient.getODataCollection<ChargeSession>({
      url: `${urls.chargeStations}${route}${resource}`,
      query,
    });

    if (this.isError(data) || !Array.isArray(data?.value)) {
      throw new Error(str.fetchChargeStationsSessions);
    }

    const { value: items, "@odata.count": total } = data;

    return { items, total };
  }

  async queryChargingSessionLocation(startDate: Date, endDate: Date) {
    const nextEndDate = new Date(endDate);
    nextEndDate.setDate(nextEndDate.getDate() + 1);

    const from = startDate.toJSON().slice(0, 10);
    const to = nextEndDate.toJSON().slice(0, 10);

    type Prop = keyof ChargeSession;
    const fromName: Prop = "startTime";
    const toName: Prop = "endTime";
    const idName: Prop = "locationId";
    const nameName: Prop = "locationName";

    const route = "/query";
    const resource = `/ChargingsSessions?$apply=filter(${fromName} ge ${from} and ${toName} lt ${to})/groupby((${idName}, ${nameName}))`;

    const data =
      await this.odataClient.getODataCollection<ChargeSessionLocation>({
        url: `${urls.chargeStations}${route}${resource}`,
      });

    if (this.isError(data) || !Array.isArray(data?.value)) {
      throw new Error(str.fetchChargeStationsSessions);
    }

    const locations = data.value.map(({ locationId, locationName }) => {
      return { locationId, locationName };
    });

    return locations;
  }

  async queryChargingSessionInitiatedBy(startDate: Date, endDate: Date) {
    const nextEndDate = new Date(endDate);
    nextEndDate.setDate(nextEndDate.getDate() + 1);

    const from = startDate.toJSON().slice(0, 10);
    const to = nextEndDate.toJSON().slice(0, 10);

    type Prop = keyof ChargeSession;
    const fromName: Prop = "startTime";
    const toName: Prop = "endTime";
    const idName: Prop = "initiatedBy";

    const route = "/query";
    const resource = `/ChargingsSessions?$apply=filter(${fromName} ge ${from} and ${toName} lt ${to})/groupby((${idName}))`;

    const data = await this.odataClient.getODataCollection<
      Pick<ChargeSession, "initiatedBy">
    >({
      url: `${urls.chargeStations}${route}${resource}`,
    });

    if (this.isError(data) || !Array.isArray(data?.value)) {
      throw new Error(str.fetchChargeStationsSessions);
    }

    const list = data.value.map((x) => ({ initiatedBy: x.initiatedBy }));

    return list;
  }

  async getChargeStationSavings() {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.chargeStations}/reporting/savings`,
    };

    const response = await this.httpClient.request<ICSSavingsResponse>(request);

    if (this.isError(response)) {
      throw new Error();
    }
    return response;
  }

  async getChargeStationTotalEnergy() {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.chargeStations}/reporting/energy`,
    };
    const totalEnergy = await this.httpClient.request<number>(request);
    if (this.isError(totalEnergy)) {
      throw new Error();
    }
    return totalEnergy;
  }

  async getChargeStationUptime() {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.chargeStations}/reporting/uptime`,
    };
    const uptime = await this.httpClient.request<IUpTimeTrendsResponse>(
      request
    );
    if (this.isError(uptime)) {
      throw new Error();
    }
    return uptime;
  }

  async submitTariff(data: TariffUpdateRequest) {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "POST",
      url: `${urls.chargeStationsFinance}/chargingstationtariffs`,
      data,
    };
    const response = await this.httpClient.request<IUpTimeTrendsResponse>(
      request
    );
    if (this.isError(response)) {
      throw new Error();
    }
    return response;
  }

  async getFinancialReportingChargersSummary(premise: Premise) {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.chargeStationsFinance}/financialReporting/chargersSummary?premise=${premise}`,
    };

    const response =
      await this.httpClient.request<IFinancialReportingSummaryResponse>(
        request
      );

    if (this.isError(response) || !response) {
      throw new Error(str.fetchFinancialReporting);
    }

    return response;
  }

  async getFinancialReporting(dateFrom: Date, datetTo: Date) {
    const fromString = dateFrom.toJSON();
    const toString = datetTo.toJSON();

    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.chargeStationsFinance}/financialreporting?dateFrom=${fromString}&dateTo=${toString}`,
    };
    const response = await this.httpClient.request<FinanceReportingResponse>(
      request
    );
    if (this.isError(response)) {
      throw new Error();
    }
    return response;
  }
}
