import { ChargeSession } from "api/domains/chargingStations/types";
import { VehicleJourney } from "api/domains/vehicles/types";
import { ChargeStation, CombustionCar, ElectricCar } from "assets/images/svgs";
import classnames from "classnames";
import StatusIcon from "pages/Dashboard/components/TableView/StatusIcon";
import TableIcon from "pages/Dashboard/components/TableView/TableIcon";
import { IDashboardTableData } from "store/slices/dashboard/types";
import { VehicleFuelType, VehicleStatus } from "store/slices/vehicles/types";
import { secondsToMinutes } from "utils/dateFormat";
import {
  formatTableDate,
  roundValue,
  roundValueWithFraction,
  showDashForCombustionVehicle,
} from "utils/formatTableData";
import { getWarrantyValue, isWarrantyElapsed } from "utils/warrantyDateUtils";
import strings from "../../assets/strings";
import "./styles.scss";
import { TableColumnConfig } from "./types";

export const dashboardTableConfig: TableColumnConfig<IDashboardTableData> = [
  {
    title: "Type",
    key: "type",
    dataIndex: "type",
    width: 40,
    render: (_el: any, data: any) => (
      <TableIcon
        type={data.type}
        isFaulty={data.statuses?.includes(VehicleStatus.Fault) || false}
      />
    ),
    formatFunc: (type) => {
      switch (type) {
        case "Electric":
          return "Electric Vehicle";
        case "chargeStation":
          return "Charge Station";
        case "Combustion":
          return "Combustion Vehicle";
        default:
          return "-";
      }
    },
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    width: 40,
    render: (_el: any, data: any) => <StatusIcon type={data.iconType} />,
    formatFunc: (_el, data) => {
      if (data.status) {
        return strings.chargeStationStatus[data.status];
      }
      if (data.statuses) {
        const statuses = data.statuses.map((s) => strings.vehicleStatus[s]);
        return statuses.join(", ");
      }
    },
  },
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    width: 130,
    sortable: true,
  },
  {
    title: "Address",
    key: "address",
    width: 150,
    dataIndex: "address",
    sortable: true,
  },
  {
    title: "Town",
    key: "town",
    width: 100,
    dataIndex: "town",
    sortable: true,
  },
  {
    title: "Postcode",
    key: "postcode",
    width: 60,
    dataIndex: "postcode",
    sortable: true,
  },
  {
    title: "Warranty",
    key: "warranty",
    width: 60,
    dataIndex: "warranty",
    render: (_el: any, data: any) => (
      <span
        className={classnames({
          "c-table__cell--error": isWarrantyElapsed(data.warranty),
        })}
      >
        {getWarrantyValue(data.warranty)}
      </span>
    ),
    sortable: true,
    formatFunc: getWarrantyValue,
  },
];

export const chargeSessionsTableConfig: TableColumnConfig<
  ChargeSession & Record<string, any>
> = [
  {
    title: "",
    key: "icon",
    dataIndex: "icon",
    width: 50,
    render: () => <ChargeStation height="34px" width="34px" />,
    exclude: true,
  },
  {
    title: "Charge Station",
    key: "chargingStationId",
    width: 130,
    dataIndex: "chargingStationId",
    sortable: true,
  },
  {
    title: "Location",
    key: "locationName",
    dataIndex: "locationName",
    sortable: true,
  },
  {
    title: "Start",
    key: "startTime",
    dataIndex: "startTime",
    width: 130,
    sortable: true,
    formatFunc: formatTableDate,
  },
  {
    title: "End",
    key: "endTime",
    width: 130,
    dataIndex: "endTime",
    sortable: true,
    formatFunc: formatTableDate,
  },
  {
    title: "Initiated by",
    key: "initiatedBy",
    dataIndex: "initiatedBy",
    sortable: true,
  },
  {
    title: "Volume (kWh)",
    key: "volume",
    dataIndex: "volume",
    align: "right",
    sortable: true,
    width: 90,
    formatFunc: roundValue,
  },
  {
    title: "Total Cost inc VAT (£)",
    key: "totalCostIncVat",
    dataIndex: "totalCostIncVat",
    align: "right",
    sortable: true,
    width: 90,
    formatFunc: (v: number) => roundValueWithFraction(v, 2),
  },
];

export const journeysTableConfig: TableColumnConfig<VehicleJourney> = [
  {
    children: [
      {
        title: "VRN",
        key: "vrn",
        dataIndex: "vrn",
        width: 120,
        sortable: true,

        render: (el: any, data: any) => {
          const icon =
            data.fuelType === VehicleFuelType.Electric ? (
              <ElectricCar height="34px" width="34px" />
            ) : (
              <CombustionCar height="34px" width="34px" />
            );
          return (
            <div className="p-table__vrn">
              {icon}
              <span>{el}</span>
            </div>
          );
        },
      },
    ],
  },
  {
    title: "Location",
    className: "p-table__header",
    children: [
      {
        title: "Start",
        key: "startPostcode",
        dataIndex: "startPostcode",
        width: 100,
        sortable: true,
      },
      {
        title: "End",
        key: "endPostcode",
        dataIndex: "endPostcode",
        width: 100,
        sortable: true,
      },
    ],
  },
  {
    title: "Journey",
    className: "p-table__header",
    children: [
      {
        title: "Start",
        key: "startTime",
        dataIndex: "startTime",
        width: 120,
        sortable: true,
        render: (el: any) => formatTableDate(el),
      },
      {
        title: "End",
        key: "endTime",
        dataIndex: "endTime",
        width: 120,
        sortable: true,
        render: (el: any) => formatTableDate(el),
      },
      {
        title: "Duration (mins)",
        key: "journeyTime",
        dataIndex: "journeyTime",
        align: "right",
        width: 90,
        sortable: true,
        render: (el: any) => secondsToMinutes(el),
      },
    ],
  },
  {
    title: "Charge",
    className: "p-table__header",
    children: [
      {
        title: "Start (%)",
        key: "startCharge",
        dataIndex: "startCharge",
        width: 80,
        align: "right",
        sortable: true,
        render: (el: number, data: any) =>
          showDashForCombustionVehicle(el, data),
      },
      {
        title: "End (%)",
        key: "endCharge",
        dataIndex: "endCharge",
        width: 80,
        align: "right",
        sortable: true,
        render: (el: number, data: any) =>
          showDashForCombustionVehicle(el, data),
      },
      {
        title: "Used (%)",
        key: "chargeUsed",
        dataIndex: "chargeUsed",
        align: "right",
        width: 70,
        sortable: true,
        render: (el: number, data: any) =>
          showDashForCombustionVehicle(el, data),
      },
    ],
  },
  {
    title: "",
    children: [
      {
        title: "kWh Used",
        key: "kwhUsed",
        dataIndex: "kwhUsed",
        align: "right",
        width: 80,
        sortable: true,
        render: (el: number, data: any) =>
          showDashForCombustionVehicle(el, data),
      },
    ],
  },
  {
    title: "",
    children: [
      {
        title: "Miles per kWh",
        key: "averageMiles",
        dataIndex: "averageMiles",
        align: "right",
        width: 80,
        sortable: true,
        render: (el: number, data: any) =>
          showDashForCombustionVehicle(el, data),
      },
    ],
  },
  {
    title: "",
    children: [
      {
        title: "Eco score (%)",
        key: "ecoSafetyScore",
        dataIndex: "ecoSafetyScore",
        align: "right",
        width: 80,
        sortable: true,
        render: (el: number, data: any) =>
          showDashForCombustionVehicle(el, data),
      },
    ],
  },
];

export const availability: TableColumnConfig<any> = [
  {
    title: "Charge station",
    key: "id",
    dataIndex: "id",
    width: 110,
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
    width: 90,
  },
  {
    title: "Availability",
    key: "availability",
    dataIndex: "availability",
  },
  {
    title: "Available",
    key: "available",
    dataIndex: "available",
  },
  {
    title: "Faulty",
    key: "faulty",
    dataIndex: "faulty",
  },
  {
    title: "Charging",
    key: "charging",
    dataIndex: "charging",
  },
  {
    title: "Plugged in idle",
    key: "idle",
    dataIndex: "idle",
  },
  {
    title: "Watchlist",
    key: "watchlist",
    dataIndex: "watchlist",
  },
];

export const utilisation: TableColumnConfig<any> = [
  {
    title: "Charge station",
    key: "id",
    dataIndex: "id",
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
  },
  {
    title: "Utilisation",
    key: "utilisation",
    dataIndex: "utilisation",
    render: (value: number) => `${value}%`,
  },
  {
    title: "Charging",
    key: "charging",
    dataIndex: "charging",
    render: (value: number) => `${value}%`,
  },
  {
    title: "Plugged in idle",
    key: "idle",
    dataIndex: "idle",
    render: (value: number) => `${value}%`,
  },
];
