import AppRoutes from "appRoutes";
import { ErrorBoundary } from "components";
import ModalFactory from "components/ModalFactory";
import { AuthenticationContextProvider } from "contexts/AuthenticationContext";
import { SubscriptionProvider } from "contexts/subscriptionContext";
import { DraxBrowserRouter } from "DraxBrowserRouter";
import React from "react";
import { Provider } from "react-redux";
import AnalyticsManager from "services/Analytics/AnalyticsManager";
import { store } from "store";
import "./styles/index.scss";

function App() {
  AnalyticsManager.initialiseAnalytics();
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <AuthenticationContextProvider>
          <SubscriptionProvider>
            <DraxBrowserRouter>
              <AppRoutes />
              <ModalFactory />
            </DraxBrowserRouter>
          </SubscriptionProvider>
        </AuthenticationContextProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
