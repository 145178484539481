import { BrowserHistory, createBrowserHistory, To } from "@remix-run/router";
import config from "config/app";
import { useAuthenticationContext } from "contexts/AuthenticationContext";
import { useSubscription } from "contexts/subscriptionContext";
import React, { useRef, useState } from "react";
import { BrowserRouterProps, Router } from "react-router-dom";

const navigateWrapper = (
  orgId: string | null,
  navigate: (to: To, state?: any) => void,
  to: To,
  state?: any
) => {
  if (typeof to == "string") {
    navigate(to, state);
  } else {
    let search = to.search ?? "";
    if (orgId != null && orgId?.length > 0) {
      const searchParams = new URLSearchParams(search);
      if (!searchParams.has(config.app.orgIdParamName)) {
        searchParams.set(config.app.orgIdParamName, orgId);
      }
      search = searchParams.toString();
    }
    navigate({ ...to, search }, state);
  }
};

export function DraxBrowserRouter({
  basename,
  children,
  window,
}: BrowserRouterProps) {
  const historyRef = useRef<BrowserHistory>();

  const subContext = useSubscription();
  const { user } = useAuthenticationContext();
  const subOrgId = subContext.orgId;
  const userOrgId = user?.orgId;

  const orgIdRef = useRef<string>();
  orgIdRef.current =
    subOrgId != null && String(userOrgId) !== String(subOrgId)
      ? subOrgId
      : undefined;

  if (historyRef.current == null) {
    const history = createBrowserHistory({ window, v5Compat: true });
    historyRef.current = history;

    const orgPush = history.push;
    const orgReplace = history.replace;
    history.push = (to, state) => {
      navigateWrapper(orgIdRef.current ?? null, orgPush, to, state);
    };
    history.replace = (to, state) => {
      navigateWrapper(orgIdRef.current ?? null, orgReplace, to, state);
    };
  }

  const history = historyRef.current;
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => {
    history.listen(setState);
  }, [history]);

  return (
    <Router
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
}
