const paths = {
  home: "/",
  signin: "/signin-oidc",
  signout: "/signout-oidc",
  reports: `/reports`,
  journeys: `/reports/journeys`,
  chargeSessions: `/reports/charge-sessions`,
  financialReporting: `/reports/financial-reporting`,
  support: "/support",
  notifications: "/notifications",
  consumption: "/consumption",
  notFound: "/404",
  correlation: "/error/correlation",
};

export default paths;
